import {
  Box,
  BoxProps,
  Container,
  Flex,
  Link,
  TextProps,
  Typography
} from "@xcorejs/ui";
import { Properties } from "csstype";

import { FC, ReactNode } from "react";
import styled, { css } from "styled-components";
import * as system from "styled-system";
import { LG, MD, SM, XS } from "../../../constants/file-resolution-breakpoints";

type HeroImageProps =
  {
    heroImageText?: string;
    heroImageBackground767: string;
    heroImageBackground767Retina?: string;
    heroImageBackground1024?: string;
    heroImageBackground1024Retina?: string;
    heroImageBackground1440?: string;
    heroImageBackground1440Retina?: string;
    heroImageBackground1920?: string;
    heroImageBackground1920Retina?: string;
    heroImageBackground2560?: string;
    heroImageBackground2560Retina?: string;
    heroImageTextAlign?: system.ResponsiveValue<Properties["textAlign"]>;
    _text?: TextProps;
    button?: ReactNode;
    _button?: BoxProps;
    newWindow?: boolean;
    targetURL?: string;
  }
  & BoxProps;

const HeroImageStyle = styled(Box)<HeroImageProps>`
  background-image: url(${props => props.heroImageBackground767});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  
  & > a > div {
    cursor: inherit;
  }

  & > a * {
    cursor: inherit;
  }
  
  ${p => p.heroImageBackground1024 && css`
    @media (min-width: ${XS}px) {
      background-image: url(${p.heroImageBackground1024});
    }
  `}
  ${p => p.heroImageBackground1440 && css`
    @media (min-width: ${SM}px) {
      background-image: url(${p.heroImageBackground1440});
    }
  `}
  ${p => p.heroImageBackground1920 && css`
    @media (min-width: ${MD}px) {
      background-image: url(${p.heroImageBackground1920});
    }
  `}
  ${p => p.heroImageBackground2560 && css`
    @media (min-width: ${LG}px) {
      background-image: url(${p.heroImageBackground2560});
    }
  `}
  
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) { 
    ${p => p.heroImageBackground1024Retina && css`
      @media (min-width: ${XS}px) {
        background-image: url(${p.heroImageBackground1024Retina});
      }
    `}
    ${p => p.heroImageBackground1440Retina && css`
      @media (min-width: ${SM}px) {
        background-image: url(${p.heroImageBackground1440Retina});
      }
    `}
    ${p => p.heroImageBackground1920Retina && css`
      @media (min-width: ${MD}px) {
        background-image: url(${p.heroImageBackground1920Retina});
      }
    `}
    ${p => p.heroImageBackground2560Retina && css`
      @media (min-width: ${LG}px) {
        background-image: url(${p.heroImageBackground2560Retina});
      }
    `}
  }
`;

const HeroImage: FC<HeroImageProps> = ({
  children,
  heroImageText,
  heroImageTextAlign = "center",
  _text,
  button,
  _button,
  targetURL,
  newWindow,
  ...props
}) => (
  <HeroImageStyle {...props}>
    <Link
      display="flex"
      v="simple"
      border="0"
      href={targetURL || undefined}
      target={newWindow ? "_blank" : "_self"}
      cursor={targetURL!.length > 1 ? "pointer !important" : "default !important"}
    >
      <AspectRatioBox>
        <Container
          position="relative"
          flexDirection="column"
          alignItems={heroImageTextAlign === "left" ? "flex-start" : heroImageTextAlign === "right" ? "flex-end" : "center"}
          justifyContent="center"
          paddingBottom="14rem"
          height={{ _: "auto" }}
        >
          <Typography
            variant="p"
            as={"div" as any}
            fontSize={{ _: "3.2rem", md: "8rem" }}
            fontWeight="bold"
            lineHeight={{ _: "3.2rem", md: "8rem" }}
            textShadow="2px 4px 4px #1e1e1e"
            color="#fff"
            textAlign={heroImageTextAlign}
            width="100%"
            maxWidth="70rem"
            {..._text}
          >
            {heroImageText}
          </Typography>
        </Container>
      </AspectRatioBox>
    </Link>

    <Flex position="absolute" bottom={{ _: "4rem", sm: "8rem" }} width="100%" justifyContent="center" {..._button}>
      {button}
    </Flex>
  </HeroImageStyle>
);

export default HeroImage;

export const AspectRatioBox: FC = ({ children }) => {
  return (
    <>
      <AspectRatioBoxStyle>
        <Box position="absolute" width="100%" height="100%" left={0} top={0}>
          {children}
        </Box>
      </AspectRatioBoxStyle>
    </>
  );
};

const AspectRatioBoxStyle = styled(Box)`
  width: 100%;
  position: relative;
  /* 340 / 767 * 100 */
  padding-bottom: 44.32856%;
  @media (min-width: ${XS}px) {
    /* padding-bottom: 750 / 1024 * 100 + '%'; */
    padding-bottom: 73.24219%;
  }
  @media (min-width: ${SM}px) {
    /* padding-bottom: 750 / 1440 * 100 + '%'; */
    padding-bottom: 52.08333%;
  }
  @media (min-width: ${MD}px) {
    /* padding-bottom: 750 / 1920 * 100 + '%'; */
    padding-bottom: 39.0625%;
  }
`;
